.otpResendTimer {
    margin-top: 5px;
    font-weight: bold;
    color: #999;
}

.remainingAttempts {
    color: #d9534f;
    font-size: 0.8em;
    font-weight: 400;
    margin-top: 0.4rem;
}

.btn {
    color: var(--primary-color) !important;
    font-weight: bold !important;
    border: none !important;
}
