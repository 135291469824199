.logo {
    text-align: center;
    color: var(--primary-color);
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: bold;
}

.stepperWrapper {
    padding: 0rem;
}

.stepperContentWrapper {
    width: 300px;
    margin: 0 auto;
}
.backLoginMain{
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--text-color);;
}
.loginLink{
    color: var(--primary-color);
    cursor: pointer;
    margin-left: 0.3rem;
}

@media screen and (max-width: 576px) {
    .stepperContentWrapper {
       display: none;
    }
  }