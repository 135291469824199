.mainContainer {
    background: var(--back-ground-muted);
    padding: 2rem;
    width: 100%;
    min-height: calc(100vh - 152px);
}

.mainChartContainer {
    display: flex !important;
    justify-content: space-around !important;
    gap: 1rem;
}
.textColor{
    color: var(--text-color);
}
@media screen and (max-width: 1200px) {
    .mainChartContainer {
        display: inline !important;
        column-gap: 40px;
    }
}
