.sidebarContainer {
    padding: var(--layout-gap);
    width: var(--side-bar-width);
    position: fixed;
    height: 100vh;
}

.sidebar {
    /* scrollbar-color: var(--primary-color) #fff; */
    scrollbar-color: rgba(17, 100, 102, 0.7) #fff;
    scrollbar-width: thin;

    overflow-y: auto;
    background-color: var(--back-ground-sidebar);
    height: 100%;
    color: var(--text-color-muted);
    cursor: pointer;
    position: relative;
}

.sidebarList {
    width: 100%;
    padding: 0;
}

.sidebarListItem,
.sidebarListHeadingItem {
    width: 100%;
    height: 50px;
    padding: 0 1.5rem;
    list-style-type: none;
    margin: 0px;
    cursor: pointer;
    color: var(--text-color-muted);
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sidebarListHeading {
    text-align: center;
    color: var(--primary-color);
}

.sidebarListItemActive {
    color: #fff;
    background-color: var(--primary-color);
    cursor: pointer;
}

.sidebarListItem:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.sidebarLogo {
    color: var(--primary-color);
    font-weight: 700px;
    font-size: 32px;
    text-align: center;
    text-transform: none;
    padding: 1.1rem;
    margin: 0 auto;
}

.divider {
    height: 1px;
    background-color: var(--text-color);
    width: 90%;
    margin: 0 auto;
    margin-bottom: 12px;
}

.sidebarListLogout {
    margin: 0 1rem 1rem 1rem;
}

.sidebarListLogoutBtn {
    width: 100%;
    background-color: #ff0000 !important;
    color: #fff !important;
    border: none !important;
    height: 48px !important;
}

.sidebarListLogoutBtn:hover {
    color: #ccc !important;
    cursor: pointer;
}

.sidebarListItemIcon {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 768px) {
    .drawerSidebar {
        scrollbar-color: rgba(17, 100, 102, 0.7) #fff;
        scrollbar-width: thin;
        overflow-y: auto;
        background-color: var(--back-ground-sidebar);
        height: 90%;
        width: 300px;
        color: var(--text-color-muted);
        cursor: pointer;
        position: relative;
        font-size: 95%;
    }
    .sidebarListLogout {
        margin: 0 0.5rem 0.5rem 0.5rem;
    }
    .sidebarLogo {
        color: var(--primary-color);
        font-weight: 800px;
        font-size: 28px;
        text-align: center;
        padding: 0.2rem;
        margin: 0 auto;
    }
    .sidebarContainer {
        width: 0px;
        height: 100vh;
        padding: 0;
    }
    .sidebar {
        display: none;
    }
}
