.headingWrapper {
    text-align: center;
    background-color: var(--back-ground-default);
    padding: 1rem;
    color: var(--primary-color);
    font-size: 1rem;
}
.headingWrapperOverview {
    text-align: center;
    background-color: var(--back-ground-default);
    padding: 0.4rem;
    color: var(--primary-color);
    font-size: 0.6rem;
    height: auto;
}

@media screen AND (min-width: 768px) {
    .headingWrapper { 
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }   
    .headingWrapperOverview { 
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }   

}

.heading {
    font-size: 1rem;
}
.headingOverview {
    font-size: 0.9rem;
}
.mainHeading {
    color: var(--primary-color);
    font-weight: bold
}

.headingValue {
    font-size: 1.2rem;
    font-weight: bold;
}
.headingValueOverview {
    font-size: 1rem;
    font-weight: bold;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footerHeading {
    color: var(--text-color-muted);
    font-size: 1.1rem;
    margin-bottom: 0.2rem !important;
}
.footerHeadingOverview {
    color: var(--text-color-muted);
    font-size: 0.7rem;
    font-weight: bold;
    /* margin-bottom: 0.3vh; */
}

.footerMainHeading {
    color: var(--primary-color);
    font-size: 1.3rem;
    margin-bottom: 0.3rem !important;
}

.marginBottomFooter{
    margin-bottom: 0.5rem;
}
.marginBottomFooterOverview{
    margin-bottom: 0.3rem;
}
