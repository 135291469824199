.dashboardWrapper {
    width: 100%;
}
.textNoAccount{
    color: var(--text-color);
}

.textCentered{
    text-align: center !important;
    color: var(--text-color) !important;
    background-color: var(--back-ground-muted) !important;
}
.cardMapping{
    background-color: var(--back-ground-muted) !important;
}
.loaderBackground{
    background-color: var(--back-ground-default);
}

.btn {
    margin-top: 1rem;
    color: #fff;
    background-color:var(--primary-color) !important;
    border: none !important;
}
