.Heading {
    color: var(--primary-color);
    font-size: 32px;
    font-weight: bold;
}

.Alert {
    background: var(--select-default) !important;
    font-size: 13px !important;
    padding: 1rem !important;
}
.mainRegistrationSpace{
    gap: 18px !important;
}

.phoneFieldContainer {
    border: 1px solid #e2e4e5;
    padding: 2rem;
}

.phoneFieldContainerHeading {
    color: var(--text-color);
    font-size: 13px;
}
.textColor{
    color: var(--text-color);
}
.btn {
    background: none !important;
    color: var(--primary-color) !important ;
    border: 1px solid #e2e4e5 !important;
}

.footerText {
    color: red;
    text-align: center;
}
