.spinner {
    margin: 70px 0;
    padding: 50px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.cardContainerWrapper {
    background: var(--back-ground-muted);
    padding: 0.8rem 1rem;
    height: calc(50vh - 32px - 47px);
    width: 100%;
    overflow: hidden;
}
.cardContainerHeading {
    color: var(--primary-color);
    font-weight: bold;
}

.cardContainerHeight {
    height: auto;
}

.pieChartWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pieChartWrapperData {
    display: flex;
    justify-content: center;
}

/* Account */

.accountMainContainerWrapper {
    background: var(--back-ground-muted);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    min-height: calc(100vh - 152px + 46px);
}

.accountContainerWrapper {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    width: 400px;
    border: 0.5px solid var(--primary-color);
    height: 500px;
}
.accountContainerWrapperWelcome {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    width: 400px;
    background-color: var(--select-default);
    border: 0.5px solid var(--primary-color);
    height: 500px;
}
.accountHeading {
    font-size: 38px;
    width: 250px;
    margin: 0 auto;
    color: var(--primary-color);
}

.accountBtn {
    background: var(--primary-color) !important;
    color: #fff;
    border: none !important;
    width: 250px;
    margin-top: 40px;
}

.modelText {
    text-align: center !important;
    margin-left: 16% !important;
}
