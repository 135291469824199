
.otpVerificationContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 0.2rem;
}

.otpVerificationHeading {
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 500;
}

.otpVerificationSubHeading {
    color: var(--text-color);
    font-size: 0.9rem;
    font-weight: 400;
}

.otpContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.otpInputWrapper input {
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: 0;
    background-color: var(--back-ground-muted);
    color: var(--select-selected);
    border: 1px solid #ccc;
    border-radius: 4px;
}

.otpInputSp {
    margin-right: 1rem;
}

.otpResendButton {
    margin: 0 auto;
    width: fit-content;
    margin-top: 1rem;
    color: var(--primary-color);
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.otpResendTimer {
    margin-top: 1rem;
    font-weight: bold;
    color: var(--text-color-muted);
}

.remainingAttempts {
    color: #d9534f;
    font-size: 0.8em;
    font-weight: 400;
    margin-top: 0.4rem;
}