.mainContainerWrapper {
    display: flex;
    justify-content: flex-start;
}

.rightContainerWrapper {
    background-color: var(--select-default);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 50%;
}
.leftContainerWrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
}


@media screen and (max-width: 576px) {
    .leftContainerWrapper {
    display: none;
    }
    .rightContainerWrapper{
        height: 100vh;
        width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .leftContainerWrapper {
    display: none;
    }
    .rightContainerWrapper{
        height: 100vh;
        width: 100%;
    }
  }