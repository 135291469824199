.csvImportWrapper {
    width: auto;
    height: 100%;
    margin: 0px auto;
}
.csvErrorButton button{
 color: #fff !important;
 background-color: var(--primary-color) !important;
 border: 1px solid #fff;
}
.errorButtonDiv{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 1rem;
}

.mainContainer {
    background: var(--back-ground-muted);
    width: 100%;
    padding: 1rem;
}
.templateFileSection{
    color: var(--text-color);
}
.textColor{
    color: var(--text-color);
}
.textColorNormal{
    color: var(--text-color);
    font-weight: 100;
}
.dragger{
    background-color: var(--select-default);
}
.textColorMuted{
    color: var(--text-color-muted);
}
.btn {
    color: #fff !important;
    background:var(--primary-color) !important;
    border: none !important;
    /* width: 100% !important; */
}
.selectApiNote{
    color: var(--primary-color);
    font-weight: 600;
}
.textColorPrimary{
    color: var(--primary-color);
    font-weight: 600;
}
.selectApiFormInput{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-evenly; */
}
.inputStyles{
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
    border: 0.5px solid var(--primary-color) !important;
    min-width: 30%;
    max-width: 100%;
}
.modelText{
    text-align: center !important;
    margin-left: 16% !important;
 }
 .apiAlreadySubmitted{
    min-height: calc(100vh - 200px );
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;   
}