.mainHeadingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.mainSmallHeadingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
}

.mainHeadingContainer h1 {
    margin-bottom: 0;
}

.mainHeading {
    color: var(--primary-color);
    font-weight: bold;
}
