.mainContainer {
    background: var(--back-ground-muted);
    padding: 2rem;
    width: 100%;
}

.mainContainerHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mainContainerDataTable {
    padding: 1rem;
    margin: 0 auto;
}

.transactionTypeSelection{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

}