.headingContainer {
    text-align: center;
    color: var(--primary-color);
    font-size: 28px;
    font-weight: bold;
}

.forgetPasswordContainer {
    text-decoration: underline;
    text-decoration-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    text-align: right;
}
.forgetPasswordContainer span {
    text-decoration: underline;
    text-decoration-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    cursor: pointer;
    text-align: right;
}
.forgetPasswordContainer span:hover {
    transition: color .3s;
    text-decoration: underline #40a9ff ;
    text-decoration-color: #40a9ff !important;
    color: #40a9ff !important;
    cursor: pointer;
    text-align: right;
}
.TextCentered {
    text-align: center !important;
    color: var(--text-color);
}
.primaryText{
    color: var(--primary-color);
}
.linkText{
    color: var(--primary-color);
    cursor: pointer;
}
.linkText:hover {
    transition: color .3s;
    text-decoration: underline #40a9ff ;
    text-decoration-color: #40a9ff !important;
    color: #40a9ff !important;
    cursor: pointer;
    text-align: right;
}