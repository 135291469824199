.logo {
    text-align: center;
    color: var(--primary-color);
    font-size: 28px;
    font-weight: bold;
}

.footerText {
    color: var(--text-color);
    text-align: center;
}
.primaryText{
    color: var(--primary-color);
}