body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --layout-gap: 0.8rem;
    --side-bar-width: 270px;
    --phone-width: 576px;
    --tablet-width: 768px;
    --laptop-width: 992px;
    --large-width: 1200px;
    --extra-large-width: 1600px;
}
html[data-theme="dark"] {
    --primary-color: #2ea043;
    --back-ground-default: #21262d;
    --back-ground-muted: #30363d;
    --back-ground-sidebar: #30363d;
    --text-color: #c9d1d9;
    --text-color-muted:#8b949e;
    --text-color-table:#fff;
    --table-back-ground-default: #21262d;
    --table-back-ground-focus: #30363d;
    --table-text-default: #fff;
    --select-default: #21262d;
    --select-default-light: #30363d;
    --select-selected: #fff;
    --select-hover: #fff;
    --text-hover: #fff;
    /* --back-ground-default: #0f0f0f;    youtube */
}
html[data-theme="light"] {
    --primary-color: #116466;
    --back-ground-default: #becbcb;
    --back-ground-muted: #fff;
    --back-ground-sidebar: #fff;
    --text-color: #363636;
    --text-color-muted:#a3aed0;
    --text-color-table:#fff;
    --table-back-ground-default: #fff;
    --table-back-ground-focus: #fafafa;
    --table-text-default: #363636;
    --select-default: #fff;
    --select-default-light: #fff;
    --select-selected: #21262d;
    --select-hover: ##03b2cb;
    --text-hover: ##363636;
    
    /* --back-ground-default: #0f0f0f;    youtube */
}

@media screen and (max-width: 576px) {
    body {
        font-size: 80%;
    }
}

@media screen and (min-width: 576px) {
    body {
        font-size: 90%;
    }
}

/* @media screen and (min-width: 1200px) {
    body {
      font-size: 110%;
    }
  } */

/* @media screen and (min-width: 1600px) {
    body {
      font-size: 125%;
    }
  } */

* {
    box-sizing: border-box !important;
}

html,
body,
#root {
    height: fit-content;
    background-color: var(--back-ground-default);
}
.ant-input input {
    text-align: inherit;
    background-color: var(--back-ground-default);
  }
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
}
input[type='number'] {
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
    appearance: textfield;
    -moz-appearance: textfield;
}
.rightContainer {
    width: 100%;
    background-color: var(--select-default);
    padding: 0rem 2rem;
}

.accountCardContainer {
    padding: 1.5rem;
}

.d-none {
    display: none;
}

.d-initial {
    display: initial;
}

.spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}


.PhoneInputInput{
    color: var(--text-color);
    background-color: var(--select-default);
    border-color: var(--primary-color);
}
.ant-drawer-body{
    background-color: var(--back-ground-sidebar);
}
.ant-drawer-content-wrapper{
    transition: all 0s
}

/* ANTD TAB */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
    /* text-shadow: 0 0 .25px var(--primary-color); */
}
.ant-tabs-ink-bar {
    background-color: var(--primary-color);
}
.ant-tabs-tab:hover {
    color: var(--primary-color);
}

.ant-tabs {
    color: var(--select-selected) !important;
}


/* ANTD SELECT */

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: var(--select-default);
    color: var(--text-color);
    border-color: var(--primary-color);
}
.ant-select-suffix{
    color: var(--text-color);
}
.ant-select-dropdown{
  background-color: var(--select-default);
}
.rc-virtual-list-holder{
  background-color: var(--select-default);
}
.ant-select-item {
    color: var(--text-color);
}
.ant-select-item-option-active {
    background-color: var(--select-selected);
}
    
/* will see what we do on hover */
/* .ant-select-item-option-active:hover {
    background-color: var(--select-hover);
    color: var(--text-color) !important;
}
.ant-select-selector:hover, .ant-select-selector:focus-within {
    border: 1px solid var(--select-selected);
    color: var(--primary-color);
} */



/* ANTD PAGINATION */
.ant-pagination-item-active {
    background-color: var(--primary-color);
    border-color: var(--primary-color) !important;
    /* color: var(--text-color) !important; */
}
.ant-pagination-item-active a {
    background-color: var(--primary-color);
    color: var(--select-hover) !important;
    font-weight: bold;
}
.ant-pagination-item-active a:hover{
    color: var(--text-color) !important;
    font-weight: bold;
}
.ant-pagination-item a {
    color: var(--text-color);
}
.ant-pagination-item:hover a {
    background-color: var(--primary-color);
    color: var(--text-color);
    border-color: var(--primary-color) !important;
}
.ant-pagination-item:hover {
    border-color: var(--primary-color);
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--text-color);
    border-color: var(--primary-color);
}
.ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: var(--primary-color);
    color: var(--text-color);
}

.ant-pagination-next button, .ant-pagination-prev button {
color: var(--text-color);
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--text-color);
}
.ant-pagination-jump-next :hover{
    color: red;
    background-color: var(--primary-color);
}
.ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link {
    background-color: var(--primary-color);
}
.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    background-color: var(--select-default);
}
.ant-pagination-jump-next, .ant-pagination-jump-prev, .ant-pagination-next, .ant-pagination-prev {
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
}
.ant-pagination-item {
    background-color: var(--select-default);
    color: var(--text-color);
}
.ant-pagination {
    color: var(--text-color) !important;
}
/* ANTD OTHERS */
.ant-alert-message {
    color: var(--text-color);
}

.ant-upload-list {
    color: var(--text-color);
    font-weight: bold;
}
.ant-upload-list-item-card-actions .anticon {
    color: var(--text-color);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
    color: var(--text-color);
    background-color: var(--back-ground-muted);
}

.ant-upload-list-item-info .ant-upload-text-icon .anticon, .ant-upload-list-item-info .anticon-loading .anticon {
    color: var(--text-color);
}

.antd-wave-shadow-color{
    color: var(--primary-color) !important;
}

.ant-empty-normal {
    color: var(--text-color);
}

.ant-form {
    background-color: var(--select-default-light) !important;
    color: var(--text-color);
}
.ant-input {
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
}
.ant-input-affix-wrapper {
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
    background-color: var(--select-default);
}

.headingColor {
    color: var(--primary-color);
    background-color: transparent;
}

.spanColor {
    color: var(--text-color-muted);
}

.ant-pagination-options {
    display: none;
}

/* .ant-select-arrow {
    top: 40% !important;
    transform: translateY(-50%) !important;
} */

.error {
    color: red;
}

.formContainer {
    padding: 1rem;
}

/* ANTD RADIO BUTTON */


.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: var(--primary-color);
}

.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--text-color-table);
    background: var(--primary-color);
    border-color: var(--primary-color);
}
/* ANTD TABLE */


th.ant-table-cell.ant-table-column-has-sorters:hover,
th.ant-table-cell.ant-table-column-has-sorters:focus,
.ant-table-thead th.ant-table-column-sort {
    background: var(--primary-color) !important;
    color: var(--text-color-table) !important;
}
td.ant-table-column-sort {
    background: var(--table-back-ground-default);
}
td.ant-table-column-sort :active{
    background: var(--table-back-ground-default);
}
.ant-table-cell-fix-left, .ant-table-cell-fix-right {
    background: var(--table-back-ground-default);

}
.ant-table-thead > tr > th {
    color: var(--text-color-table);
    background: var(--primary-color);
    border: 1px solid var(--text-color-table);
}
.ant-table-tbody {
    color: var(--table-text-default) !important;
    background: var(--table-back-ground-default) !important;
    border: 1px solid var(--table-text-default);
}
.ant-table-tbody>tr.ant-table-row:hover>td{
    background: var(--table-back-ground-focus) !important;
}
.ant-table-tbody:focus {
    background: var(--table-back-ground-focus) !important;
}

/* ANTD MODEL */
 .ant-modal-header {
    color: var(--text-color) !important;
    background-color: var(--select-default);
}
.ant-modal-body {
    color: var(--text-color);
    background-color: var(--select-default);
} 
.ant-modal-footer {
    color: var(--text-color);
    background-color: var(--select-default);
}
.ant-modal-content{
    color: var(--text-color);
    background-color: var(--select-default);
}
.ant-modal-close {
    color: var(--text-color) !important;
}
.ant-modal-title {
color: var(--text-color) !important;
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background: var(--select-default);
}
/* ANTD POPOVER */


.ant-popover-inner-content {
    color: var(--text-color) !important;
}
.ant-popover-message {
    color: var(--text-color) !important;
}
.ant-popover-inner {
    background-color: var(--back-ground-default);
}
/* .ant-popover-inner { */

/* .ant-table-tbody .ant-table-cell:active {
    background-color: red;
  } */
/* .ant-table-column-sorter{
    background-color: var(--select-default);
} */