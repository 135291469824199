.inputWrapper {
    width: 100%;
    display: flex;
    flex-direction: column !important;
}

.label {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 8px;
    display: flex !important;
    color: var(--text-color) !important;
    justify-content: flex-start !important;
}

/* .input {
    outline: 0;
    border: 0;
    height: 40px;
    padding: 0 1rem;
    color: var(--text-color) !important;
    background-color: var(--back-ground-primary);
} */

/* .inputWrapper input:focus, .inputWrapper input:hover {
    outline: 0;
    border: 0;
    border-color: #4D5959;
    box-shadow: none;    
} */




.inputStyles{
    outline: 0;
    border: 0;
    height: 40px;
    padding: 0 1rem;
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
    border: 0.5px solid var(--primary-color) !important;
}
::placeholder {
    color:var(--text-color);
    opacity: 1; 
  }