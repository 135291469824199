.mainContainer {
    display: flex;
    justify-content: center;
}

.pieContainerSmall {
    width: 25.88vh;
}

.pieContainerLarge {
    width: 400px;
}

.btn{
    height:25px;
    width:75px;
    border-radius: 30px;
    background-color:#116566ec;
    border:none;
    color:white;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 12px;
}