.stepVerification {
    font-size: 22px;
    font-weight: bold;
    color: var(--primary-color);
}

.verificationImg {
    color: var(--text-color) !important;
    height: 100px;
}

.verificationCode {
    color: var(--text-color);
}
