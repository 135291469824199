.listWrapper {
    background: var(--back-ground-muted) !important;
    display: flex;
    color: var(--text-color) !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16px;
    color: var(--text-color);
    cursor: pointer;
}
.textBackgroundColor {
    color: var(--text-color);
    background-color: var(--back-ground-muted);
}
