.layout {
  background-color: var(--fall-back-color);
  height: 100%; 
}
  
.layoutInner {
  padding: var(--layout-gap) var(--layout-gap) var(--layout-gap) 0;
  margin-left: var(--side-bar-width);
  width: calc(100% - var(--side-bar-width));
  background-color: var(--fall-back-color);
  min-height: 100%;
}

.sidebarContainer {
  padding: var(--layout-gap);
  width: var(--side-bar-width);
  position: fixed;
  height: 100vh;
}


.sidebar {
  scrollbar-color: rgba(17, 100, 102, 0.7) #fff;
  scrollbar-width: thin;

  overflow-y: auto;
  background-color: #fff;
  height: 100%;
  color: var(--text-color-muted);
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 770px) {
  .layoutInner {
  width: 100%;
  margin-left: 0px;
  padding:var(--layout-gap);
  }
}