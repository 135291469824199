.headerContainer {
    background: var(--back-ground-muted) !important;

    padding: 0 2rem !important;
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;

}

.mainHeading {
    color: var(--primary-color);
    font-weight: bold;
    margin: 0px;
    text-transform: capitalize;
}
.subscriptionText{
    color: var(--text-color);
}