.coinDataTab {
    background-color: var(--table-back-ground-default);
    color: var(--text-color);
    padding: 0.5rem 1rem;
    width: 150px;
    text-align: center;
}

.textNormal {
    color: var(--text-color-muted);
}

.textBold {
    color: var(--text-color);
    font-weight: bold;
}

.mainContainerCoinMarket {
    background-color: var(--table-back-ground-default);
    border: 1px solid var(--text-color);
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.buttonTicker {
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1rem;
    width: 150px;
    height: 30px;
    border-radius: 2rem;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
}

.buttonTicker:hover {
    background-color: var(--primary-color);
    color: #fff;
}
