.header {
    display: flex;
    justify-content: space-between;
}

.headerHeading {
    color: var(--primary-color);
    font-weight: bold;
}

.footer {
    display: flex;
    justify-content: space-between;
    
}

.footerHeading {
    color: var(--primary-color);
}

.dropDownMenu{
    width: 120px;
     height: 39px; 
}