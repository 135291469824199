.mainContainer {
    background: var(--back-ground-muted);
    display: grid;
    gap: 20px;
    justify-content: center;

    min-height: calc(100vh - 152px);
}
.inputLabel {
    color: var(--text-color);
    font-weight: 500;
}
.textColor{
    color: var(--text-color);
}
.textColorMuted{
    color: var(--text-color-muted);
}
.inputStyles{
    border: 0.5px solid var(--primary-color) !important;
}
.validationContainer {
    color: #a9a9a9;
    font-size: 12px;
}

.imgContainer {
    display: flex;
    justify-content: center;
}

.btn {
    color: #fff !important;
    background:var(--primary-color) !important;
    border: none !important;
    width: 100% !important;
}
