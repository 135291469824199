.footer {
    display: flex;
    justify-content: space-between;
}

.footerHeading {
    color: var(--primary-color);
}

.dropDownMenu {
    width: 120px;
    height: 39px;
}

.cardMainContainer {
    margin: 70px;
    display: flex;
    justify-content: center;
}

.cardContainer {
    width: 700px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color) !important;
    background: aliceblue !important;
}

.btn{
    height:35px;
    width:200px;
    border-radius: 8px;
    background-color:#116566ec;
    border:none;
    color:white;
    margin-left: 20%;
    cursor: pointer;
    font-size: 16px;
}