.mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0.8rem;
}

.mainHeading {
    color: var(--primary-color);
    font-weight: bold;
    margin: 0px;
    text-transform: capitalize;
}

.rightContainer {
    display: flex;
    gap: 1rem;
}