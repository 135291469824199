.footer {
    display: flex;
    justify-content: space-between;
}

.footerHeading {
    color: var(--primary-color);
}

.dropDownMenu {
    width: 120px;
    height: 39px;
}

.transactionModelButtonActive{
    width: 80px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    color: #fff !important;
    background-color: var(--primary-color) !important;
    font-size: 0.8rem  !important;
    height: 28px !important;
}
.transactionModelButtonActive:hover{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
.transactionModelButtonActive:active{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
.transactionModelButtonActive:focus{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
.transactionModelButtonDisabled{
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--textMuted);
}