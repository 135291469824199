.mainContainer {
    width: 100%;
    background: var(--back-ground-muted);
    padding: 1rem;
    height: 100%;
}

.formattingContainer {
    border: 0.5px solid var(--primary-color);
    padding: 1rem;
}

.formattingContainerSelector {
    display: flex;
    justify-content: space-between;
    width: 350px;
}

.DropDownMenu {
    width: 160px;
    color: var(--primary-color);
    background-color: var(--back-ground-muted);
}

.btnContainer {
    text-align: end;
}

.btn {
    background: var(--primary-color) !important;
    color: #fff;
    border: none !important;
    width: 150px;
    margin-top: 40px;
}

.link {
    text-align: end;
    color: var(--primary-color);
    text-decoration: underline;
}
.inputStyles{
    background-color: var(--select-default) !important;
    color: var(--text-color) !important;
    border: 0.5px solid var(--primary-color) !important;
}