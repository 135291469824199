.generateReportButton{
    width: 120px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    color: #fff !important;
    background-color: var(--primary-color) !important;
    font-size: 0.8rem  !important;
    height: 28px !important;
}
.generateReportButton:hover{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
.generateReportButton:active{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
.generateReportButton:focus{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
